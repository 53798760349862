export default {
  created () {
    this.canBack()
  },
  methods: {
    canBack () {
      window.webkit.messageHandlers.canGoBack.postMessage(0)
    }
  }
}
